/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, Box, TextField, Checkbox, FormControlLabel } from '@mui/material';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import { createNewSession, updateSession } from '../../redux/dataSlice';
import DeckSelectDropdown from '../DeckSelectDropdown';

const CreateEditSessionModal = ({ handleClose, open, session, isCreateSession, client }) => {
  const dispatch = useDispatch();

  const clientDeck = client && client.clientDeck;
  const clientName = client && (client.attendeeStyles.friendlyName || client.name);
  const [selectedDeck, setSelectedDeck] = useState(null);

  const validationSchema = Yup.object().shape({
    date: Yup.string().required('Date is required'),
    location: Yup.string()
      .required('Location is required')
      .max(30, 'Location can not be longer than 30 characters'),
    presenter: Yup.string()
      .required('presenter is required')
      .max(30, 'Presenter can not be longer than 30 characters')
  });

  // console.log('session.date', session?.id, session?.date);

  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      location: '',
      presenter: '',
      ...session,
      date: session ? moment.utc(session.date).format('YYYY-MM-DDThh:mm') : ''
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm(formOptions);

  useEffect(() => {
    if (open) {
      if (isCreateSession) {
        setSelectedDeck(clientDeck[0]);
      } else {
        // reset(session);
        setSelectedDeck(_.find(clientDeck, (deck) => deck.id === session.deckId));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleSessionSubmitClick = (data) => {
    // validate

    data.date += ':00.000Z'; // add seconds and milliseconds to date string with Z so it wont be converted to local time or utc
    const { date, presenter, location, deleted } = data;

    if (isCreateSession) {
      dispatch(
        createNewSession(
          date,
          presenter,
          location,
          selectedDeck.id,
          _.isEmpty(selectedDeck.configData)
        )
      );
    } else {
      dispatch(
        updateSession({
          ...session,
          date,
          presenter,
          location,
          status: deleted ? 4 : data.status,
          deckId: selectedDeck.id
        })
      );
    }

    handleClose();
  };

  const closeModal = () => {
    reset();
    handleClose();
  };
  /*
  Open : is the modal currently open
  (!session && !isCreateSession) If the session doesn't exist, we can
  continue BUT if edit was selected, we need to check for that (EditSession = !isCreateSession)
  */
  if (!open || (!session && !isCreateSession)) return;

  return (
    <Modal open={open} onClose={closeModal} disableScrollLock>
      <Box className="modal-context">
        <Button sx={{ position: 'absolute', right: '5%', top: '5%' }} onClick={closeModal}>
          <CloseIcon sx={{ fontSize: '40px' }} />
        </Button>

        <div>
          <p className="modal-header">
            {isCreateSession ? `New ${clientName} Session` : `Edit ${clientName} Session`}
          </p>
          <p>
            {isCreateSession
              ? 'Please complete all fields shown below to help your participants find your session.'
              : 'Please review your session information listed below. If you need to make any changes, edit the new information in the appropriate field before clicking "Submit Changes."'}
          </p>
          <form onSubmit={handleSubmit((data) => handleSessionSubmitClick(data))}>
            <div className="modal-textfields">
              {errors.date && <p className="error-text">{errors.date.message}</p>}
              <TextField
                id="date"
                label="Date"
                type="datetime-local"
                // name="date"
                sx={{ width: '100%', marginBottom: '2vh' }}
                {...(errors.date && { error: true })}
                {...register('date')}
                InputLabelProps={{
                  shrink: true
                }}
              />
              {errors.location && <p className="error-text">{errors.location.message}</p>}
              <TextField
                id="location"
                label="Location"
                name="location"
                sx={{ width: '100%', marginBottom: '2vh' }}
                {...(errors.location && { error: true })}
                placeholder="Location"
                {...register('location')}
                InputLabelProps={{
                  shrink: true
                }}
              />
              {errors.presenter && <p className="error-text">{errors.presenter.message}</p>}
              <TextField
                id="presenter"
                label="Presenter"
                name="presenter"
                sx={{ width: '100%', marginBottom: '2vh' }}
                placeholder="Presenter Name"
                {...(errors.presenter && { error: true })}
                {...register('presenter')}
                InputLabelProps={{
                  shrink: true
                }}
              />
              {selectedDeck && (
                <DeckSelectDropdown
                  selectedDeckId={selectedDeck.id}
                  setSelectedDeck={setSelectedDeck}
                  clientDeck={clientDeck}
                  direction={'column'}
                  deckStyles={{ marginBottom: '2vh', flexDirection: 'column' }}
                />
              )}
              {!isCreateSession && (
                <FormControlLabel
                  control={<Checkbox {...register('deleted')} />}
                  label="Delete Session"
                />
              )}
              <Button variant={'contained'} type="submit">
                {isCreateSession ? 'Create A New Session' : 'Submit Changes'}
              </Button>
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  );
};

export default CreateEditSessionModal;
